const dev = {
  region: 'eu-west-1',
  apiEndpoint: 'https://dashboard-api.dev.srdops.com',
  cognito: {
    USER_POOL_ID: 'eu-west-1_4zjWYWqyo',
    WEB_CLIENT_ID: '1k9sehhjbakgok2sf4qdhng74i',
  },
}

const pd = {
  region: 'eu-west-1',
  apiEndpoint: 'https://dashboard-pd-api.dev.srdops.com',
  cognito: {
    USER_POOL_ID: 'eu-west-1_KO0JKUdnN',
    WEB_CLIENT_ID: '195ki7vq4nnb0k7qj0kinigls5',
  },
}

const prod = {
  region: 'eu-west-1',
  apiEndpoint: 'https://dashboard-api.srdops.com',
  cognito: {
    USER_POOL_ID: '',
    WEB_CLIENT_ID: '',
  },
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : pd
export default config
