import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface SideBarState {
  sidebarVisible: boolean
  sidebarUnFoldable: boolean
}

const initialState: SideBarState = {
  sidebarVisible: true,
  sidebarUnFoldable: false,
}

export const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.sidebarVisible = action.payload
    },
    setSidebarUnFoldable: (state, action: PayloadAction<boolean>) => {
      state.sidebarUnFoldable = action.payload
    },
  },
})

export const { setSidebarVisible, setSidebarUnFoldable } = sideBarSlice.actions

export const selectSidebarVisible = (state: RootState) => state.sidebar.sidebarVisible
export const selectSidebarFoldable = (state: RootState) => state.sidebar.sidebarUnFoldable

export default sideBarSlice.reducer
