import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface ContextState {
  name: string
}

const initialState: ContextState = {
  name: 'global',
}

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
  },
})

export const { setContext } = contextSlice.actions

export const selectContext = (state: RootState) => state.context.name

export default contextSlice.reducer
