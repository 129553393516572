import { configureStore } from '@reduxjs/toolkit'
import sideBarReducer from 'features/sideBarSlice'
import contextReducer from 'features/contextSlice'
import { dashboardApi } from 'features/apiSlice'

const store = configureStore({
  reducer: {
    sidebar: sideBarReducer,
    context: contextReducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dashboardApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
