import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import './scss/style.scss'
import config from './config'

if (process.env.REACT_APP_STAGE === 'production') {
  // Insert prod amplify config with federated identities
} else {
  Amplify.configure({
    Auth: {
      region: config.region,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.WEB_CLIENT_ID,
    },
  })
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export const apiEndpoint = config.apiEndpoint

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

function App() {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      )}
    </Authenticator>
  )
}

export default App
